<script>
	import { StoryblokComponent, storyblokEditable } from '@storyblok/svelte';
	import MetaBlock from './MetaBlock.svelte';

	export let content;
</script>

<div class="w-full" use:storyblokEditable={content}>
	<MetaBlock
		{content}
		defaults={{
			description: 'seo.description.home',
			meta_keywords: 'seo.meta_keywords.home',
			title: 'seo.title.home'
		}}
	/>
	<div class="w-full bg-white space-y-wrapper-small">
		{#each content?.header || [] as blok (blok._uid)}
			<StoryblokComponent {blok} />
		{/each}

		<main class="space-y-wrapper-small">
			{#each content?.body || [] as blok, i (blok._uid)}
				<StoryblokComponent {blok} key={blok._uid} position={1 + i} />
			{/each}

			{#each content?.footer || [] as blok (blok._uid)}
				<StoryblokComponent {blok} key={blok._uid} />
			{/each}
		</main>
	</div>
</div>
